.flex-box {
    display: flex;
    overflow: hidden;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;

    &.cols {
        flex-direction: column;
    }

    .flex-col {
        position: relative;
        overflow: hidden;
        flex: 1;
    }

    .flex-fix {
        position: relative;
        flex: 0;
    }
}
