#sitemap {
    h2 {
        font-size: em(13);
        font-weight: 500;
        line-height: 1.75;
		margin-top: 2rem;
		margin-bottom: 1rem;

        @include xs {
            font-size: 1rem;
        }
	}

    article li {
        margin-bottom: 0.625em;
    }

    .post-content {
        margin: 0 !important;
    }
}
