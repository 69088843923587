#overlay {
    position: absolute;
    top: em(60);
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: background 0.3s ease, visibility 0.3s ease, opacity 0.3s ease;
    
    &.visible,
    .mobile-menu-open & {
        background: rgba(0,0,0,0.75);
        visibility: visible;
        opacity: 1;
    }
    
    @include m {
        top: em(80);
    }
}