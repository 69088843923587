* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: $font-primary;
    font-size: em(16);
    line-height: 1.2;
    letter-spacing: 0;
    color: $color-dark-grey;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    position: relative;
}

strong,
b {
    font-weight: 500;
}

em,
i {
    font-style: italic;
}

a {
    display: block;
    text-decoration: none;
    color: inherit;
    outline: none;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

#image-preloader {
    position: absolute;
    overflow: hidden;
    top: -9999px;
    left: -9999px;
    width: 1px;
    height: 1px;
}

.ajax-loader {
    background-image: url(../gfx/ajax-loader.gif);
    background-color: $color-light-grey;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 6em 6em;
}

.bg-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

#page {
    position: relative;
    max-width: em(1440);
    width: 100%;
    margin: 0 auto;
}

.container {
    position: relative;
    max-width: em(1092);
    width: 100%;
    background: white;
    margin: 0 auto;
    padding: 2em 1em 0 1em;

    &.home {
        @include m {
            margin-top: -2.5em;
        }
    }
}

.inner {
    max-width: em(1092);
    width: 100%;
    margin: 0 auto;
    padding: 0 1em;
    @include xs {
        padding: 0 2em;
    }
    @include m {
        padding: 0 4em;
    }
}

.wrapper {
    @include xs {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    @include m {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

.caps-title {
    font-size: em(10) !important;
    color: $color-dark-grey;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include xxs {
        font-size: em(11) !important;
    }

    &:not(.exception)::before {
        content: '';
        width: rem(22);
        display: inline-block;
        border-top: 1px solid $color-dark-grey;
        margin-right: rem(6);
        vertical-align: super;
    }
}

.caps-headline {
    font-size: em(11);
    text-transform: uppercase;
    letter-spacing: 1px;
    &:before,
    &:after {
        content: '';
        width: rem(22);
        display: inline-block;
        border-top: 1px solid $color-dark-grey;
        vertical-align: super;
    }
    &:before {
        margin-right: rem(6);
    }
    &:after {
        margin-left: rem(6);
    }
}

.section-spacer {
    overflow: hidden;
    margin: rem(8) 0 1.5rem 0;
    .caps-title {
        @include xs {
            margin: 0 1rem;
        }
        @include m {
            margin: 0 3rem;
        }
    }
}

.section-title {
    @extend .wrapper;
}

.title {
    font-size: em(18);
    line-height: 1.25;

    * + & {
        margin-top: rem(12);
    }
}

.small-title {
    font-size: em(12);
    font-weight: 500;
    color: $color-dark-grey;
}

.desc {
    font-size: rem(14);
    font-weight: 300;
    line-height: (20/14);

    * + & {
        margin-top: 1em;
    }
}

.button {
    @extend button;
    &::after {
        @extend [class^="icon-"];
        content: "\e901";
        font-size: rem(9);
        margin-left: 1.5em;
    }
}

#scroll-to-top {
    display: none;
    font-size: em(36);
    width: rem(36);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background: white;
    padding: 0;
    color: black;
    z-index: 5;
    cursor: pointer;
    transform: rotate(270deg);
    transition: all 0.3s ease;
    .no-touchevents &:hover {
        color: $color-primary;
    }
    @include l {
        right: 5%;
        bottom: 2rem;
    }
}

.social {
    font-size: em(12);
    font-weight: 500;
    line-height: (18/12);
    .text::after {
        content: '';
        display: inline-block;
        width: rem(70);
        height: 2px;
        background: black;
        vertical-align: middle;
        margin: 0 rem(6) 0 rem(14);
    }
    .icon {
        @extend [class^="icon-"];
        font-size: rem(14);
        vertical-align: middle;
        display: inline-block;
    }
    a {
        transition: color 0.3s ease;
        padding: 0 rem(7);

        .no-touchevents &:hover {
            color: $color-primary;
        }
    }
}

.special .social {
    float: right;
    margin: 1rem 0;
}

.image-hover {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .no-touchevents &:hover::before {
        opacity: 0.25;
        visibility: visible;
    }
}

.interaction {
    font-size: rem(11);
    transform: translateY(50%);
    .item {
        color: black;
        margin: 0 em(8);
        display: inline-block;
    }
    .icon {
        margin-right: 0.3em;
        font-size: em(22);
    }
    .icon-speech-bubble {
        vertical-align: middle;
    }
}

[data-margin*="t"] {
    margin-top: 3em;
}

[data-margin*="b"] {
    margin-bottom: 3em;
}

#hofmeister-tv {
    margin-top: em(24);
}

.search-form {
    display: block;
    margin-bottom: em(24);

    @include m {
        display: none;
    }

    input {
        border: 1px solid #ccc;
        height: 1.5rem;
        font-size: 0.75em;
        line-height: 1.5rem;
        padding: 0 0.25rem;
        background: transparent url(../gfx/search.png) no-repeat right center/auto 1.125em;
        width: 100%;
    }
}
