#post {
    @include s {
        > .sticky {
            padding-top: 1.5em;
        }
    }

    .post-content {
        overflow: hidden;
        
        &:not(.exception) {
            margin-top: 2em;

            @include s {
                margin-top: 3em;
            }
        }
        
        &.exception > *:first-child {
            margin-top: 0 !important;
        }
    }

    .caps-title {
        @extend .wrapper;

        & + .title {
            margin-top: 1.5rem;
        }
    }

    .title {
        @extend .wrapper;

        @include m {
            font-size: 2rem;
        }
    }

    .subtitle {
        display: block;
        font-size: em(14);
        font-weight: 300;
        margin-top: 0.5rem;
        @extend .wrapper;

        @include m {
            font-size: em(18);
        }
    }

    h1 {
        @extend .title;
        @extend .wrapper;
    }

    @each $h in h2,
    h3,
    h4,
    h5,
    h6 {
        .post-content #{$h} {
            @extend .wrapper;
            @extend .title;

            @include m {
                font-size: rem(20);
            }
        }

        .post-content * + #{$h} {
            margin-top: 2rem;
        }
    }

    p,
    ul {
        @extend .desc;
        @extend .wrapper;
        @include xs {
            font-size: 1rem;
        }
    }

    * + p {
        margin-top: 1.5rem;
    }

    a {
        display: inline-block;
        text-decoration: underline;
        transition: color 0.3s;

        &:hover {
            color: $color-primary;
        }
    }

    ul li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        a {
            text-decoration: none;
        }
    }

    img,
    iframe {
        margin: 2em 0;

        &.thumbnail {
            margin-top: 0 !important;
        }

        @include xs {
            margin: 3em 0;
        }
    }

    iframe {
        width: 100%;
    }

    p iframe {
        margin-top: 0 !important;
    }

    .acf-map {
        height: 450px;
    }

    #author {
        @extend .wrapper;
        font-size: rem(12);
    }

    .social {
        display: none;

        a {
            text-decoration: none;
        }

        @include xs {
            display: block;
            float: right;
        }
    }

    .author {
        margin-top: 1.5rem;
    }

    .pagination {
        overflow: hidden;
        padding: 1em 0;
        margin: 1.5em 0;
        border-top: 1px solid $color-light-grey;

        [rel="prev"],
        [rel="next"] {
            font-size: em(12);
            font-weight: 500;
            line-height: 2rem;
            text-decoration: none;
            color: $color-grey;
        }

        [rel="prev"] {
            float: left;

            &::before {
                @extend [class^="icon-"];
                content: "\e906";
                font-size: rem(9);
                line-height: 2rem;
                float: left;
                margin-right: 1.5em;

            }
        }

        [rel="next"] {
            float: right;

            &::before {
                @extend [class^="icon-"];
                content: '\e901';
                font-size: rem(9);
                line-height: 2rem;
                float: right;
                margin-left: 1.5em;
            }
        }
    }

    a {
        transition: color 0.3s ease;

        &:hover {
            color: $color-primary !important;
        }
    }
}
