#hero {
    overflow: hidden;
    border-bottom: 1px solid $color-light-grey;

    @include m {
        border-bottom: none;
        margin-top: -2.5em;
    }

    &.exception {
        margin-top: 0;
        display: none;

        @include s {
            display: block;
        }
    }

    .slider {
        @include m {
            &:hover .slick-arrow {
                opacity: 1;
                visibility: visible;
            }
        }

        .slick-slide {
            position: relative;

            .wrapper {
                @include m {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    max-width: em(1092);
                    width: 100%;
                    margin: 0 auto;
                    padding: 0 1em;
                }

                &.right {
                    justify-content: flex-end;
                }
            }

            .content {
                overflow: visible !important;
                position: relative;
                text-align: center;

                @include m {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3em 1.5em;
                    margin: 0 em(48);
                    background: rgba(255,255,255,0.9);
                }

                .content-inner {
                    position: relative;
                    width: 100%;
                    padding: 1.5em 10% 3em 10%;

                    @include xxs {
                        padding-left: 20%;
                        padding-right: 20%;
                    }

                    @include xs {
                        padding-left: 25%;
                        padding-right: 25%;
                    }

                    @include m {
                        margin-bottom: em(10);
                        padding: 0;
                    }
                }

                .button {
                    display: none;

                    @include m {
                        display: block;
                        position: absolute;
                        top: auto;
                        bottom: -1rem;
                        left: 3rem;
                        right: 3rem;
                    }
                }
            }

            .caps-title::after {
                content: '';
                width: rem(22);
                display: inline-block;
                border-top: 1px solid $color-dark-grey;
                margin-left: rem(6);
                vertical-align: super;
            }

            .title {
                transition: color 0.3s ease;

                .no-touchevents &:not(.exception):hover {
                    color: $color-primary;
                }

                @include xs {
                    font-size: rem(32);
                    margin: 0.75rem 0;
                }

                @include m {
                    font-size: rem(26);
                    max-height: rem(114);
                }
            }

            .desc {
                @include m {
                    font-size: rem(18);
                }

                @include l {
                    font-size: rem(20);
                }
            }
        }

        .slick-arrow {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
            @extend [class^="icon-"];
            color: rgba(255,255,255,0.75);
            font-size: em(36);
            margin-top: -0.5em;
            cursor: pointer;
            transition: color 0.3s, opacity 0.3s, visibility 0.3s;

            &.slick-next {
                left: auto;
                right: 0;
            }

            &:hover {
                color: rgba(255,255,255,1);
            }

            &.slick-prev::before {
                content: "\e900";
            }

            &.slick-next::before {
                content: "\e902";
            }
        }

        .slick-dots {
            position: absolute;
            left: 50%;
            bottom: em(19);

            @include m {
                display: none;
            }

            li {
                left: -50%;
                position: relative;
                float: left;
                padding: em(5);
                cursor: pointer;

                span {
                    width: em(6);
                    height: em(6);
                    border-radius: 50%;
                    background: $color-grey;
                    float: left;
                    cursor: pointer;
                }

                &.slick-active span {
                    background: $color-dark-grey;
                }
            }
        }
    }

    .no-slide {
        position: relative;

        .wrapper {
            position: static;

            &.right {
                justify-content: flex-end;
            }

            @include s {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        .content {
            @include s {
                overflow: visible !important;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin: 0 auto;
                background: rgba(255, 255, 255, 0.9);
                text-align: center;
                width: 50%;
            }

            @include m {
                width: 40%;
            }

            .content-inner {
                position: relative;
                padding: 0 2em;
                margin: 2em 0 em(50) 0;

                @include s {
                    width: 100%;
                }
            }

            .button {
                position: static;

                @include s {
                    position: absolute;
                    top: auto;
                    bottom: -1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 50%;
                }
            }
        }

        .title {
            font-size: rem(24);
            max-height: rem(86.4);
            margin-top: 0.815rem;
            margin-bottom: 1rem;
            transition: color 0.3s ease;

            &:hover {
                color: $color-primary;
            }

            @include l {
                font-size: rem(32);
                max-height: rem(115.2);
            }
        }

        .time {
            font-size: em(12);
            line-height: em(18);
        }

/*
        .time::after {
            content: '';
            display: inline-block;
            width: 4.375rem;
            height: 2px;
            background: black;
            vertical-align: middle;
            margin: 0 0.875rem;
        }
*/

        .interaction {
            display: inline-block;
            transform: translateY(-2%);
        }

        .blog-autor {
            display: inline-block;

            .bg-image {
                width: em(30);
                float: left;
                transform: translateY(-25%);
                border-radius: 50%;
            }

            .autor {
                white-space: nowrap;
                font-size: em(11);
                text-transform: uppercase;
                letter-spacing: rem(1);
                margin-left: rem(46);
                transform: translateY(25%);
            }
        }
    }
}
