#popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    display: none;
    
    @include xs {
        display: block;
    }

    #popup-bg {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.75);
    }
    
    &.visible {
        opacity: 1;
        visibility: visible;
        
        .content {
            position: relative;
            transform: translate(-50%, -50%) scale(1, 1);
            opacity: 1;
            visibility: visible;
        }
    }
    
    .content {
        transform: translate(-50%, -50%) scale(0.9, 0.9);
        transition: transform 0.3s ease, visibility 0.3s ease, opacity 0.3s ease;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 50%;
        top: 50%;
        max-width: em(640);
        width: 100%;
        cursor: default;
        overflow: auto;
        
        .ratio {
            margin: 1em;
        }
        
        .overlay-inner {
            padding: 4em 6em;
        }
        
        .close {
            position: absolute;
            cursor: pointer;
            top: 1.5rem;
            right: 1.5rem;
            
            .icon {
                display: block;
                transform: rotate(45deg);
                font-weight: 300;
                font-size: 3em;
                color: $color-grey;
                line-height: 0.5em;
            }
        }
        
        .title {
            font-weight: 500;
            font-size: 2em;
        }
        
        .desc ins {
            font-weight: bold;
            border-bottom: 1px solid $color-primary;
            text-decoration: none;
            
        }

        form {
            position: absolute;
            bottom: 4em;
            width: 100%;
            
            input[type="email"] {
                width: calc(100% - 120px);
                max-width: rem(260);
                display: inline-block;
                float: left;
                height: rem(36);
                font-size: em(12);
                line-height: rem(36);
                padding: 0 rem(16);
            }

            input[type="submit"] {
                width: rem(120);
            }
        }
    }
}