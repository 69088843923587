@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?t55o3p');
    src: url('fonts/icomoon.eot?t55o3p#iefix') format('embedded-opentype'),
         url('fonts/icomoon.ttf?t55o3p') format('truetype'),
         url('fonts/icomoon.woff?t55o3p') format('woff'),
        url('fonts/icomoon.svg?t55o3p#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu::before {
  content: "\e904";
}
.icon-search::before {
  content: "\e907";
}
.icon-speech-bubble::before {
  content: "\e909";
}
.icon-thumbs-up::before {
  content: "\e90a";
}
.icon-arrow-left::before {
  content: "\e900";
}
.icon-arrow-left-2::before {
  content: "\e906";
}
.icon-arrow-right-2::before {
  content: "\e901";
}
.icon-arrow-right::before {
  content: "\e902";
}
.icon-facebook::before {
  content: "\e903";
}
.icon-logo::before {
  content: "\e905";
}
.icon-instagram::before {
  content: "\ea92";
}
.icon-youtube::before {
  content: "\ea9d";
}