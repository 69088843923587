$MQ_BREAKPOINTS: 'xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl';
$MQ_BREAKPOINTSIZES: (
    'xxxs': 360,
    'xxs': 480,
    'xs': 640,
    's': 768,
    'm': 960,
    'l': 1280,
    'xl': 1440,
    'xxl': 1920,
    'xxxl': 2440
);
$MQ_ORIENTATIONS: 'landscape', 'portrait';

// extra small devices, phones
@mixin xxxs {
    @media only screen and (min-width: 360px) {
        @content;
    }
}

// extra small devices, phones
@mixin xxs {
    @media only screen and (min-width: 480px) {
        @content;
    }
}

// extra small devices, phones and tablets
@mixin xs {
    @media only screen and (min-width: 640px) {
        @content;
    }
}

// small devices, tablets
@mixin s {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

// medium devices, desktops
@mixin m {
    @media only screen and (min-width: 960px) {
        @content;
    }
}

// large devices, wide screens
@mixin l {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

// large devices, wide screens
@mixin xl {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}

// large devices, wide screens
@mixin xxl {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}

// large devices, wide screens
@mixin xxxl {
    @media only screen and (min-width: 2440px) {
        @content;
    }
}

// landscape
@mixin landscape {
    @media (orientation: landscape) {
        @content;
    }
}

// portrait
@mixin portrait {
    @media (orientation: portrait) {
        @content;
    }
}

@mixin mq-mixin($breakpoint) {
    @if $breakpoint == 'xxxs' {
        @include xxxs {
            @content;
        }
    }

    @if $breakpoint == 'xxs' {
        @include xxs {
            @content;
        }
    }

    @if $breakpoint == 'xs' {
        @include xs {
            @content;
        }
    }

    @if $breakpoint == 's' {
        @include s {
            @content;
        }
    }

    @if $breakpoint == 'm' {
        @include m {
            @content;
        }
    }

    @if $breakpoint == 'l' {
        @include l {
            @content;
        }
    }

    @if $breakpoint == 'xl' {
        @include xl {
            @content;
        }
    }

    @if $breakpoint == 'xxl' {
        @include xxl {
            @content;
        }
    }

    @if $breakpoint == 'xxxl' {
        @include xxxl {
            @content;
        }
    }
}
