#news {
    #hero {
        .no-slide {
            .content {
                @include s {
                    width: 70%;
                }

                @include m {
                    width: 60%;
                }
            }
        }
    }

    #newsfeed {
        @include s {
            margin-top: 3em;
        }
    }
}
