#impressions {
    overflow: hidden;
    
    .isotope-item {
        margin: em(8) 0;
    }
    
    .bg-image {
        .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255,255,255,0.9);
            margin: 1em;
            padding: 1em;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            transform: scale(0.95,0.95);
            transition: transform 0.3s ease, visibility 0.6s ease, opacity 0.6s ease;
            
            .title {
                width: 100%;
                font-size: em(13);
                line-height: rem(24);
                font-weight: 300;
                margin-top: 0 !important;
                height: auto !important;
                
                .highlight {
                    font-size: rem(20);
                    font-weight: 500;
                }
            }
        }
        
        .no-touchevents &:hover .overlay {
            opacity: 1;
            visibility: visible;
            transform: scale(1,1);
            transition: transform 0.3s ease, visibility 0.3s ease, opacity 0.3s ease;
        }
    }
    
    a .title .highlight {
        transition: color 0.3s ease;
    }
    
    a .title:hover .highlight {
        color: $color-primary;
    }
    
    .square-big::before {
        @include m {
            padding-top: calc(100% + 8px);
        }
    }
    
    .content {
        overflow: hidden;
        margin: 1.5em 0 1.5em;
            
        @include xs {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @include m {
            margin: 1.5em 3em 2.5em 3em;
        }
	}
	
	.title {
		margin-top: 0.75rem;
		height: 2.375em;
	}
}