input, textarea, select, button {
    font-family: $font-primary;
    letter-spacing: 0;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    outline: 0;
}

input[type="submit"],
input[type="button"],
button {
    display: inline-block;
    cursor: pointer;
    height: rem(36);
    padding: 0 rem(16);
    font-size: rem(11);
    line-height: rem(36);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    background: black;
    color: white;
    white-space: nowrap;
    transition: background 0.3s ease;

    &:hover {
        background: $color-primary;
    }
}
