.special {
    position: relative;
    display: none;

    @include xs {
        display: block;
        margin-bottom: 5em;
    }

    @include m {
        margin-bottom: 0;
    }

    & + .special {
        margin-top: em(48);
    }

    .row {
        overflow: visible;
    }

    .title {
        transition: color 0.3s ease;
        margin-bottom: 0.5em;

        .no-touchevents &:not(.big):hover {
            color: $color-primary;
        }

        @include xs {
            font-size: rem(32);

            &.big {
                font-size: rem(50);
                line-height: 1;
                margin-top: 1em;
            }
        }
    }

    .date {
        line-height: 1.5em;
        font-size: em(20);
        font-weight: 300;
    }

    .intro {
        margin-bottom: em(18);

        @include xs {
            margin-bottom: 0;
            padding-left: 3.5em !important;
        }
        
        @include m {
            &.exception {
                min-height: em(320);
            }
        }
    }

    .intro-image {
        display: none;

        @include xs {
            display: block;
        }
    }

    .link {
        margin: 0.5em 0 2.5em 0;
        overflow: visible;

        @include xs {
            margin: 0;
            background: rgba(255,255,255,0);
            transition: background 0.3s, border-color 0.3s;
            border: 2px solid transparent;

            &.right {
                position: absolute;
                top: 50% !important;
                right: 0;
                left: auto !important;
                padding-right: rem(6);
            }

            &.bottom {
                margin-top: 2em !important;
            }

            .item-inner {
                position: relative;
                background: white;
                padding: em(8) 0 0 em(8);
            }
        }

        @include m {
            &.right {
                top: 5em !important;
                padding-right: rem(8);
            }
        }

        .no-touchevents &:hover {
            background: white;
            border-color:$color-primary;

            .button {
                opacity: 1;
                visibility: visible;
                transition: visibility 0.3s ease, opacity 0.3s ease, background 0.3s ease;
            }
        }

        a {
            position: relative;

            @include xs {
                padding-left: em(40);
            }
        }

        .button {
            position: absolute;
            top: auto;
            right: 2rem;
            bottom: -1rem;
            left: 2rem;
            transition: visibility 0.6s ease, opacity 0.6s ease, background 0.3s ease;

            @include xs {
                display: inline-block;
                opacity: 0;
                visibility: hidden;
                top: 2.5rem;
                right: auto;
                bottom: auto;
                left: 0;
                margin-left: rem(-8);
            }
        }
    }

    .content {
        padding: 1.5em 1em em(40) 1em;
        border: 1px solid $color-light-grey;

        @include xs {
            padding: 1.5em em(8);
            border: none;
        }
    }
}
