$grid-columns: 24;
$grid-width: 100%;

@mixin column-sizing-loop() {
    > .col-0 {
        display: none;
    }

    @for $i from 1 through $grid-columns {
        .col-#{$i} {
            display: block;
            // flex: 0 1 #{$grid-width / $grid-columns * $i}; // not properly working in IE
            // https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
            // Workaround:
            flex: 0 1 auto;
            width: $grid-width / $grid-columns * $i;
        }
    }

    @each $breakpoint in $MQ_BREAKPOINTS {
        $class: 'col-#{$breakpoint}';

        @include mq-mixin($breakpoint) {
            > .#{$class}-0 {
                display: none;
            }

            @for $i from 1 through $grid-columns {
                .#{$class}-#{$i} {
                    display: block;
                    // flex: 0 1 #{$grid-width / $grid-columns * $i}; // not properly working in IE
                    // https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
                    // Workaround:
                    flex: 0 1 auto;
                    width: $grid-width / $grid-columns * $i;
                }
            }
        }
    }
}

@mixin column-spacing-loop($col-config, $type, $sign: '', $value_multiplication: 1) {
    $unit: map-get($col-config, 'unit');
    $value: map-get($col-config, 'initial');
    $steps: map-get($col-config, 'steps');

    #{$type}-left: #{$sign}#{$value*$value_multiplication}#{$unit};
    #{$type}-right: #{$sign}#{$value*$value_multiplication}#{$unit};

    @if map-has-key($col-config, 'breakpoints') {
        $breakpoints: map-get($col-config, 'breakpoints');

        @each $breakpoint in $breakpoints {
            $value: $value + $steps;

            @include mq-mixin($breakpoint) {
                #{$type}-left: #{$sign}#{$value*$value_multiplication}#{$unit};
                #{$type}-right: #{$sign}#{$value*$value_multiplication}#{$unit};
            }
        }
    }
}

.row {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &:not(.exception) {
        margin-left: -0.5em;
        margin-right: -0.5em;

        > [class*='col-'] {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }

    &:not(.exception).spacing-l {
        margin-left: -2.5em;
        margin-right: -2.5em;

        > [class*='col-'] {
            padding-left: 2.5em;
            padding-right: 2.5em; 
        }
    }

    &[data-align="center"] {
        align-items: center;
    }

    @include column-sizing-loop;

    > [class*='col-'] {
        position: relative;
        float: left;
        overflow: hidden;

        &.overflow {
            overflow: visible;
        }
    }
}
