#subjects {
    .section-title {
        margin-bottom: rem(24);
    }

    .row, .item, .ratio, a {
        overflow: visible !important;
    }

    .item {
        margin: 0.5em 0 2.5em 0;

        @include xs {
            margin-bottom: 0.5em;
        }

        @include s {
            margin-bottom: 2.5em;
        }

        @include l {
            margin-bottom: 0.5em;
        }

        a {
            position: relative;

            .bg-image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .ratio:nth-child(2) {
                position: relative;
                background: rgba(255, 255, 255, 0.9);

                @include xs {
                    position: absolute;
                    top: auto;
                    right: 1em;
                    bottom: 2em;
                    left: calc(50% + 8px);
                }

                @include s {
                    position: relative;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                @include l {
                    position: absolute;
                    top: auto;
                    right: 1em;
                    bottom: 2em;
                    left: calc(50% + 8px);
                }

                &::before{
                    display: none;

                    @include xs {
                        display: block;
                    }

                    @include s {
                        display: none;
                    }

                    @include l {
                        display: block;
                    }
                }

                .content,
                .content .ellipsis {
                    position: relative;

                    @include xs {
                        position: absolute;
                    }

                    @include s {
                        position: relative;
                    }

                    @include l {
                        position: absolute;
                    }
                }
            }

            .content {
                overflow: hidden;
                border: 1px solid $color-light-grey;

                @include xs {
                    border: none;
                }

                @include s {
                    border: 1px solid $color-light-grey;
                }

                @include l {
                    border: none;
                }

                .title {
                    margin-top: rem(12);
                    transition: color 0.3s ease;
                }

                .no-touchevents &:hover .title {
                    color: $color-primary;
                }

                .desc {
                    margin-top: rem(12);
                }

                .ellipsis {
                    overflow: hidden;
                    position: relative;
                    padding: 1.5em 1em 2.5em 1em;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    @include xs {
                        position: absolute;
                        top: 1.5em;
                        right: 1em;
                        bottom: 2em;
                        left: 1em;
                        padding-top: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }

                    @include s {
                        height: em(150);
                        position: relative;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 1.5em 1em 2em 1em;
                    }

                    @include l {
                        position: absolute;
                        top: 1.5em;
                        right: 1em;
                        bottom: 2em;
                        left: 1em;
                        padding-top: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        .button {
            position: absolute;
            top: auto;
            right: 2rem;
            bottom: -1rem;
            left: 2rem;
        }
    }
}
