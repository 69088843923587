.banner-1 {
    margin: 3em 0;
}

.banner {
    margin: em(8) 0 3em 0;

    @include m {
        margin-top: em(-32);
    }

    * {
        overflow: visible !important;
    }

    .content {
        position: relative;
        background: $color-light-grey;
        padding: 2em 1em 3em 1em;

        @include xs {
            padding: 2em 0 2em 1.5em;
        }

        .button {
            position: absolute;
            top: auto;
            right: 2rem;
            bottom: -1rem;
            left: 2rem;

            @include xs {
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }

        .row {
            position: static;
            display: flex;
            align-items: center;

            @include xs {
                margin: 0;
            }

            > * {
                position: static;
            }
        }
    }

    @each $h in h1, h2, h3, h4, h5, h6 {
        #{$h} {
            @extend .title;
        }
    }

    p {
        @extend .desc;
        margin-top: 0;
    }
}

.banner-row {
    @extend .row;
    overflow: visible;
}

.slider-banner {
    overflow: visible !important;

    .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 3rem 1rem;

        .big-title {
            @extend .title;
            font-size: em(30);
        }

        .price {
            line-height: 1.5em;
            font-weight: 500;
            letter-spacing: rem(1);
            color: $color-primary;
            
            span {
                color: $color-dark-grey;
                text-decoration: line-through;
            }
        }
    }

    .slides {
        position: relative;

        .prev,
        .next {
            position: absolute;
            z-index: 10;
            width: 50%;
            height: 100%;
        }

        .next {
            right: 0;
        }
    }

    .image-slider {
        margin-top: 1.5rem;
    }

    .slider-control {
        margin-top: 1rem;

        .icon-arrow-left-2,
        .icon-arrow-right-2 {
            font-size: em(11);
            padding: 0.25rem 1rem;
        }

        .icon-arrow-left-2 {
            float: left;
        }

        .icon-arrow-right-2 {
            float: right;
        }

        .slides {
            font-size: em(14);
            line-height: 1rem;
        }

        .line {
            display: inline-block;
            content: '';
            width: 2rem;
            height: 2px;
            vertical-align: middle;
            margin: 0 0.875rem;
            background-color: $color-dark-grey;
        }
    }

    .desc-slider {
        margin-top: 1.5rem;
    }

    &[data-content="center"] .content {
        text-align: center;
    }

    &[data-bg="grey"]:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 0.5em;
        background-color: $color-light-grey;
    }

    &[data-button="bottom"] {
        .button {
            position: absolute;
            bottom: -1rem;
            left: 2rem;
            right: 2rem;
        }
    }
}
