#brochures {
    .container {
        margin-top: 2rem;

        @include xs {
            padding: 0 2em;
        }

        @include m {
            padding: 0 1em;
        }
    }
}

#brochure-image.ajax-loader {
    min-height: 300px;
}

#brochure-info {
    padding-right: 1rem;

    p {
        padding-left: 3rem;
        font-size: 0.75em;
    }

    .subline {
        margin-top: 1.5rem;
        color: $color-grey;
        text-transform: uppercase;

        &:before {
            content: '';
            width: 1.375rem;
            display: inline-block;
            border-top: 1px solid $color-grey;
            margin-right: 0.375rem;
            vertical-align: super;
        }
    }

    .open-hint {
        font-weight: 700;
        line-height: 1.5em;
        margin-top: 0.75rem;
    }

}

#lists {
    @include m {
        border-left: 1px solid $color-light-grey;
    }

    > .row {
        margin-bottom: 2.5rem;
    }

    h1 {
        margin-bottom: 0.75rem;
    }

    .list > a {
        cursor: pointer;
        padding-top: 0.75rem;
        font-size: 0.875em;
        white-space: nowrap;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $color-light-grey;
            margin: 0.75rem 0.5rem 0 0.5rem;
        }

        .name {
            font-weight: 300;
        }

        .arrow {
            line-height: 1rem;
            text-align: right;

            .icon-arrow-right-2 {
                padding-right: 0.5em;
            }
        }

        // &.active,
        &:hover {
            color: $color-primary;
        }
    }
}
