#header {
    position: relative;
    z-index: 1;

    #header-inner {
        max-width: em(1092);
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        background: white;

        @include m {
            overflow: visible;
        }

        #top-bar {
            overflow: hidden;
            height: em(60);
            line-height: em(60);
            padding: 0 1em;

            @include xs {
                padding: 0 2em;
            }

            @include m {
                padding: 0 4em;
            }

            #logo {
                font-size: em(20);
                line-height: inherit;
                color: $color-primary;
                float: left;

                @include m {
                    font-size: em(24);
                }
            }

            .icons-mobile {
                float: right;
                margin-right: em(-8);

                @include m {
                    display: none;
                }

                a,
                button {
                    outline: none;
                    background: none;
                    font-size: em(26);
                    line-height: inherit;
                    float: left;
                    height: 100%;
                    padding: 0 rem(8);
                    color: black;
                    cursor: pointer;
                }
            }

            #right {
                display: none;
                float: right;

                @include m {
                    display: block;
                }

                #sub-nav {
                    float: left;
                    height: em(60);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    ul li {
                        float: left;
                        font-size: em(12);
                        line-height: (24/12);
                        color: $color-grey;

                        a {
                            padding-right: 1.5rem;
                            transition: color 0.3s;

                            .no-touchevents &:hover {
                                color: $color-dark-grey;
                            }
                        }

                        .icon {
                            vertical-align: middle;
                            margin-right: rem(8);
                        }
                    }
                }

                form {
                    float: left;
                    margin-left: 1.5rem;

                    input[type="search"] {
                        border: 1px solid #ccc;
                        height: rem(24);
                        font-size: em(12);
                        line-height: rem(24);
                        padding: 0 rem(4);
                        background: transparent url(../gfx/search.png) no-repeat right center / auto em(18);
                    }

                    ::-webkit-search-cancel-button {
                        display: none;
                    }
                }
            }
        }

        #main-nav {
            overflow: hidden;
            display: none;

            .mobile-menu-open & {
                display: block;
            }

            @include m {
                display: block !important;
                margin-top: em(20);
                padding: 0 3em;
            }

            > ul {
                @include m {
                    display: flex;
                    justify-content: space-between;
                    height: em(40);
                    overflow: hidden;

                    padding: 0 rem(5);
                }

                @include l {
                    padding: 0;
                }

                > li {
                    font-size: em(14);
                    font-weight: 400;
                    line-height: rem(36);
                    border-top: 1px solid $color-light-grey;
                    transition: color 0.3s;

                    @include m {
                        font-size: 1em;
                        line-height: 1;
                        float: left;
                        border-top: none;
                    }

                    .no-touchevents &:hover {
                        color: $color-primary;

                        @include m {
                            border-bottom: 2px solid $color-primary;
                        }
                    }

                    > a {
                        position: relative;
                        padding: 0 1rem;

                        @include xs {
                            padding: 0 2em;
                        }

                        @include m {
                            padding: 0 rem(9);
                            height: rem(40);
                        }

                        @include l {
                            padding: 0 rem(16);
                        }
                    }

                    > ul {
                        padding: 0 3.5em;
                        display: none !important;
                        background: white;
                        border-top: 1px solid $color-light-grey;

                        @include m {
                            width: 100%;
                            max-width: 68.25em;
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%, 0);
                            z-index: 99;
                            border-color: #ccc;

                            .sub-menu-open &.visible {
                                display: flex !important;
                            }
                        }

                        > li {
                            border-left: 1px solid $color-light-grey;
                        }

                        &:not(.exception) > li:nth-child(3n + 0) {
                            border-right: 1px solid $color-light-grey;
                        }

                        &:not(.exception) > li:last-child:not(:nth-child(3n + 0)) {
                            border-right: 1px solid $color-light-grey;
                        }

                        .content {
                            padding: 1.5em 1em 2em 1em;
                        }

                        .text {
                            font-size: 1em;
                            line-height: 1.2;
                            margin-top: 0.75rem;
                            transition: color 0.3s;
                        }

                        .no-touchevents a:hover .caps-title {
                            color: $color-primary;
                        }

                        .no-touchevents a:hover .caps-title::before {
                            border-color: $color-primary;
                        }

                        .caps-title {
                            line-height: 1;
                        }
                    }

                    .no-touchevents &:hover > ul {
                        display: flex;
                    }

                    &.sub {
                        > ul {
                            color: $color-dark-grey;
                            line-height: 1.2;
                        }

                        .change {
                            display: none;
                        }

                        .change.visible {
                            display: block;
                        }

                        .selection-list {
                            overflow: visible;

                            li {
                                overflow: visible;
                                position: relative;

                                .no-touchevents &:hover {
                                    background-color: $color-light-grey;
                                }

                                a {
                                    padding: 0.5rem;
                                    border-bottom: 1px solid $color-light-grey;

                                    &.selected {
                                        background-color: $color-light-grey;
                                    }
                                }

                                .title {
                                    font-size: 1rem;
                                    margin-top: 0;
                                }
                            }
                        }

                        li.single-location .content {
                            padding: 1.5em 1em;
                        }

                        li.single-location .content .caps-title {
                            margin-bottom: 0.75rem;
                        }

                        li.single-location .content .address {
                            line-height: 1.5rem;
                            padding-top: 0.75rem;
                        }

                        li.times .title {
                            font-size: 1em;
                            line-height: 1.75em;
                            margin-top: 0.5rem;
                        }

                        li.times .time {
                            line-height: 1.5em;
                            font-weight: 300;
                        }

                        li.times .small-title {
                            margin: 1.5rem 0 1rem;

                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        .additional {
                            border-right: 1px solid $color-light-grey;

                            li {
                                .no-touchevents &:hover {
                                    background-color: $color-light-grey;
                                }

                                a {
                                    padding: 0.5rem;
                                    border-bottom: 1px solid $color-light-grey;
                                }

                                .title {
                                    font-size: 1rem;
                                    margin-top: 0;
                                }
                            }
                        }

                        li.additional .button {
                            top: auto;
                            bottom: 1rem;
                            left: 1rem;
                            right: 1rem;
                        }
                    }
                }
            }
        }
    }
}
