#history {
    #hero {
        .content {
            margin: 0;
            left: 75%;
            transform: translate(-50%, 0);
        }
    }
}

#timeline {
    #year-list {
        position: relative;
        display: none;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 5rem;

        @include s {
            display: inline-block;
        }

        li {
            font-size: em(12);
            float: left;
            color: $color-grey;
            margin-left: 1.25em;

            &:first-child {
                margin-left: 0;
            }

            &.active {
                color: $color-dark-grey;
                font-weight: 500;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 3.5rem;
                    height: 2px;
                    margin-left: 1.25em;
                    vertical-align: middle;
                    background-color: $color-dark-grey;
                }
            }
        }
    }

    .timeline-item {
        margin-top: 6rem;

        &:first-of-type {
            margin-top: 0;

            @include s {
                margin-top: 6rem;
            }
        }

        @include s {
            &:nth-child(2n) {
                direction: rtl;

                & > * {
                    direction: ltr;
                }
            }
        }

        .bg-image {
            height: 100%;
        }

        .text {
            position: relative;
            background-color: $color-white;
            margin: -1rem 1rem 0 1rem;
            padding: 1.5rem;
            padding-top: 1rem;

            @include xxs {
                margin-top: -1.5rem;
            }

            @include s {
                margin: 0;
                padding: 3rem;
            }
        }

        .year {
            font-size: em(40);
        }

        .title {
            line-height: 1.5em;
        }

        .caps-title {
            margin-top: 3rem;
        }
    }
}
