#sidebar {
    position: relative;

    @include m {
        &::after {
            content: '';
            position: absolute;
            top: 1.5em;
            left: 0;
            width: 1px;
            bottom: 0;
            background: $color-light-grey;
        }
    }

    .sidebar-content {
        padding-top: 1.5em;
    }

    .item {
        * + &:not(:first-of-type) {
            margin-top: 2.5rem;
        }

        &.exception .content {
            border: 1px solid $color-light-grey;
        }

        .ratio {
            overflow: visible !important;
        }

        a {
            position: relative;

            @each $h in h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                #{$h} {
                    transition: color 0.3s ease;
                }

                .no-touchevents &:hover #{$h} {
                    color: $color-primary;
                }
            }

            .bg-image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .no-touchevents &:hover .caps-title {
                color: $color-dark-grey !important;
            }
        }

        p a {
            display: inline;
            transition: color 0.3s ease;

            .no-touchevents &:hover {
                color: $color-primary;
            }
        }

        img {
            margin-bottom: em(20);
        }

        .content {
            overflow: hidden;

            &.border {
                border: 1px solid $color-light-grey;
            }

            @each $h in h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                #{$h} {
                    @extend .title;
                }
            }

            .caps-title {
                font-size: rem(11) !important;
                margin-bottom: 0 !important;
            }

            p {
                @extend .desc;
            }

            &.exception {
                .caps-title {
                    margin-top: 1.25rem;
                }

                .title {
                    margin-top: 0.75rem;
                    width: 100%;
                }

                * + .desc {
                    margin-top: 0.5em !important;
                    width: 100%;
                }
            }

            .ellipsis {
                overflow: hidden;
                position: absolute;
                top: 1.5em;
                right: 1em;
                bottom: 3em;
                left: 1em;
            }
        }

        .button {
            position: absolute;
            top: auto;
            bottom: -1rem;
            left: 2rem;
            right: 2rem;
            opacity: 0;
            visibility: hidden;
            transform: scale(0.95,0.95);
            transition: transform 0.3s ease, visibility 0.6s ease, opacity 0.6s ease, background 0.3s ease;

            .touchevents & {
                display: none;
            }
        }

        .no-touchevents &:hover .button {
            opacity: 1;
            visibility: visible;
            transform: scale(1,1);
            transition: transform 0.3s ease, visibility 0.3s ease, opacity 0.3s ease, background 0.3s ease;
        }
    }

    .newsletter,
    .current-events {
        display: none;

        @include m {
            display: block;
        }
    }

    .show-all {
        width: 100%;
        margin-top: 2.5em;

        .button {
            width: 100%;
        }
    }

    .social {
        margin: 2rem 0;
    }

    .caps-title {
        margin-bottom: rem(24);
    }

    #blog-lastest-posts {
        .lastest-posts-item {
            .link {
                padding-top: 1em;
                padding-bottom: 1em;
                border-bottom: 1px solid $color-light-grey;

                .content {
                    overflow: hidden;
                    position: relative;

                    .ellipsis {
                        margin-top: em(20);
                        font-size: em(13);
                        line-height: em(18);
                        font-weight: bold;
                        position: absolute;
                        left: 0.5em;
                        right: 0.5em;
                        bottom: 0;
                        top: em(11);
                        -webkit-transition: color 0.3s ease;
                        transition: color 0.3s ease;

                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }

            &:nth-of-type(1) .link {
                padding-top: 0;
            }
        }
    }
}
