#press {
    overflow: hidden;
    
    > .sticky {
        padding-top: 1.5em;
    }
    
    .title.section-title {
        @extend .wrapper;
        
        @include m {
            font-size: 2rem;
        }
    }
    
    .item {
        position: relative;
        border-bottom: 1px solid $color-light-grey;
        
        &:first-of-type {
            border-top: 1px solid #eee;
            margin-top: 2em;
        }
        
        > a {
            padding: 2em 0;
            
            .content {
                overflow: hidden;
                padding: 0 1.5em 0 1em;
                
                h2 {
                    @extend .title;
                    transition: color 0.3s ease;
                }
                
                h3 {
                    margin-top: rem(24);
                    
                    @include xs {
                        margin-top: 0;
                    }
                    
                    .source {
                        color: $color-grey;
                    }
                }
                
                p {
                    @extend .desc;
                    
                    strong, b {
                        font-weight: 300 !important;
                    }
                }
                
                p + p {
                    margin-top: 0 !important;
                }
                
                .ellipsis {
                    max-height: em(150);
                    margin-bottom: 1.5em;
                    
                    @include xs {
                        max-height: em(120);
                    }
                    
                    @include s {
                        max-height: em(150);
                    }
                    
                    .title {
                        transition: color 0.3s ease;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
                
                .buttons {
                    @include xs {
                        position: absolute;
                        bottom: 0;
                        left: 1em;
                        right: 1em;
                    }
                    
                    .read-more {
                        float: left;
                    }
                }
            }
            
            .no-touchevents &:hover h2 {
                color: $color-primary;
            }
        }
    }
    
    > #infscr-loading {
        img {
            width: 100px;
            margin: 0 auto;
        }
        
        p {
            padding-top: 2em;
            text-align: center;
        }
    }
}
