$ratios: (
    (3,1),
    (5,2),
    (2,1),
    (16,9),
    (3,2),
    (10,7),
    (4,3),
    (5,4),
    (1,1),
    (4,5),
    (3,4),
    (5,7),
    (2,3),
    (1,2)
);

@mixin ratio_sizes($size: '') {
    $class: 'data-ratio';

    @if $size != '' {
        $class: 'data-ratio-#{$size}';
    }

    @each $ratio in $ratios {
        $width: nth($ratio, 1);
        $height: nth($ratio, 2);

        &[#{$class}='#{$width}:#{$height}']:before {
            padding-top: #{($height/$width)*100%};
            content: '';
            display: block;
        }
    }

    &[#{$class}='fill'] {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.ratio {
    position: relative;
    overflow: hidden;
    > * {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @include ratio_sizes;

    @each $breakpoint in $MQ_BREAKPOINTS {
        @include mq-mixin($breakpoint) {
            @include ratio_sizes($breakpoint);
        }
    }
}
