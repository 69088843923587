#events {
    .content {
        overflow: hidden;
        margin: 1.5em 0 1.5em 0;

        @include xs {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @include m {
            margin: 1.5em 3em 2.5em 3em;
        }
    }

    .title {
        margin-top: 0.75rem;
        transition: color 0.3s ease;
    }

    .no-touchevents & .item:hover {
        .title {
            color: $color-primary;
        }
    }
}
