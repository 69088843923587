#newsletter {
    margin-top: 1rem;

    h2 {
        margin-top: 2rem;
        font-weight: 300;
    }

    p {
        margin-top: 1.5rem;
        line-height: 1.5em;
    }
}

#form-newsletter {
    @include xs {
        > div > div:first-child {
            padding-right: 2em;
        }
    }

    p {
        margin-top: 1rem;
    }

    label {
        display: block;
        margin-top: 1.5rem;
        font-size: 0.875em;
        line-height: 1.5em;
    }

    input {
        margin-top: 1.5rem;
        width: 100%;
        height: rem(36);
        padding: 0 1rem;
    }

    input[type="text"],
    input[type="checkbox"],
    input[type="radio"] {
        border: 1px solid #ccc;
    }

    input[type="checkbox"] {
        -webkit-appearance: checkbox;
        width: auto;
    }

    input[type="radio"] {
        -webkit-appearance: radio;
        width: auto;
    }
}
