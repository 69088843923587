#newsfeed {
    position: relative;

    .slider {
        margin: em(24) -1em 0 -1em;
        padding-bottom: em(24);
    }

    .slick-list {
        padding: 0 0.5em;
    }

    .slick-dots {
        position: absolute;
        left: 50%;
        bottom: 0;

        li {
            left: -50%;
            position: relative;
            float: left;
            padding: em(5);
            cursor: pointer;

            span {
                width: em(6);
                height: em(6);
                border-radius: 50%;
                background: $color-grey;
                float: left;
                cursor: pointer;
            }

            &.slick-active span {
                background: $color-dark-grey;
            }
        }
    }

    .newsfeed-item, a {
        overflow: visible !important;
    }

    .item {
        margin: em(8) 0;
        padding: 0 em(8);

        .no-touchevents & {
            padding-bottom: em(32);
        }

        a {
            position: relative;
            box-sizing: border-box;
            border: 1px solid transparent;

            .bg-image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .content {
                overflow: hidden;
                border: 1px solid $color-light-grey;

                .title {
                    font-size: em(18) !important;
                    transition: color 0.3s ease;

                    .no-touchevents &:hover {
                        color: $color-primary;
                    }
                }

                .ellipsis {
                    overflow: hidden;
                    position: absolute;
                    top: 1.5em;
                    right: 1em;
                    bottom: 3em;
                    left: 1em;
                }

                p {
                    @extend .desc;

                    strong, b {
                        font-weight: 300 !important;
                    }
                }
            }

            .text-box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: auto;
                margin: 1rem;
                padding: 1rem;
                background-color: $color-white;

                .title {
                    transition: color 0.3s;

                    &:hover {
                        color: $color-primary;
                    }
                }

                &[data-pos="bottom"] {
                    bottom: 0;
                }
            }
        }

        .button {
            position: absolute;
            top: auto;
            bottom: -1rem;
            left: 2rem;
            right: 2rem;
            opacity: 0;
            visibility: hidden;
            transform: scale(0.95,0.95);
            transition: transform 0.3s ease, visibility 0.6s ease, opacity 0.6s ease, background 0.3s ease;

            .touchevents & {
                display: none;
            }
        }

        .no-touchevents &:hover .button {
            opacity: 1;
            visibility: visible;
            transform: scale(1,1);
            transition: transform 0.3s ease, visibility 0.3s ease, opacity 0.3s ease, background 0.3s ease;
        }
    }
}
