/* Font */
$font-primary: 'Ubuntu', sans-serif;

/* Convert pixels into em/rem */
$font-size-base: 16;

@function em($pixel) {
    @return #{$pixel/$font-size-base}em;
}

@function rem($pixel) {
    @return #{$pixel/$font-size-base}rem;
}

/* Colors */
$color-primary: #e1001a;
$color-light-grey: #eee;
$color-grey: #929292;
$color-dark-grey: #424242;
$color-white: #ffffff;
