#service {
    margin-top: 2em;
    margin-bottom: 2em;
    
    .content {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .bg-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .content-inner {
        width: 100%;
        background: $color-light-grey;
        padding: 1.5em 1em;
        h1 {
            font-size: rem(26);
            margin-bottom: rem(20);

            @include xxs {
                font-size: rem(28);
                // margin-bottom: rem(22);
            }

            @include l {
                font-size: em(30);
                // margin-bottom: rem(10);
            }
        }
        h2 {
            font-size: rem(15);
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 1rem;
            margin-bottom: rem(8);

            @include xxs {
                font-size: em(18);
                // margin-bottom: rem(10);
            }

            // @include l {
            //     font-size: em(20);
            //     margin-bottom: rem(10);
            // }
        }
        p {
            font-size: rem(13);
            margin-top: 0.75rem;
            font-weight: 300;
            line-height: 1.38462;
        }
        img {
            margin: 1.5em 0;
        }
        .location {
            .title {
                line-height: 1.5em;
                font-weight: 300;
                font-size: em(16);
                margin-bottom: 1rem;
            }
        }
        .times {
            .small-title {
                //margin-top: 1.5rem;
            }
            .time {
                line-height: 1.5em;
                font-weight: 300;
            }
            .title {
                font-size: 1em;
                font-weight: 400;
                line-height: 1.75em;
                margin-bottom: 0;
            }
        }
    }
    .social {
        float: right;
        margin: 1rem 0;
    }
}

.section-title + #service {
    margin-top: em(24);
}
