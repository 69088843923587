#blog {
    overflow: hidden;

    #blog-list {
        @include s {
            padding-top: 1.5em;
        }

        > .item {
            position: relative;
            border-bottom: 1px solid $color-light-grey;

            @include s {
                &:not(.exception):first-of-type {
                    display: none;
                }
            }

            a {
                padding-top: 2em;
                padding-bottom: 2em;

                .content {
                    overflow: hidden;
                    padding: 0 1.5em 0 1em;

                    &.exception {
                        padding-left: 0.5em;
                    }

                    .caps-title {
                        margin-top: rem(24);

                        @include xs {
                            margin-top: 0;
                        }

                        .autor {
                            color: $color-grey;
                        }
                    }

                    .ellipsis {
                        max-height: rem(150);
                        margin-bottom: 1.5em;

                        @include xs {
                            max-height: rem(120);
                        }

                        @include s {
                            max-height: rem(125);
                        }

                        .title {
                            transition: color 0.3s ease;
                        }
                    }

                    p {
                        @extend .desc;

                        strong, b {
                            font-weight: 300 !important;
                        }

                        & + p {
                            margin-top: 0 !important;
                        }
                    }

                    .buttons {
                        @include xs {
                            position: absolute;
                            bottom: 0;
                            left: 1em;
                            right: 1em;
                        }

                        .read-more {
                            float: left;
                        }
                    }
                }

                .no-touchevents &:hover .title {
                    color: $color-primary;
                }
            }

            .interaction {
                position: absolute;
                right: 1.5rem;
                bottom: 3.2rem;
                top: auto;
            }
        }
        > #infscr-loading {
            img {
                width: 100px;
                margin: 0 auto;
            }
            p {
                padding-top: 2em;
                text-align: center;
            }
        }
    }
}

#blog-lastest-posts {
    display: none;
    margin-top: 2em;
    padding-right: 0.5em;

    @include xs {
        display: block;
    }

    @include m {
        margin-top: 0;
    }
}

#instagram {
    display: none;
    padding-left: 0.5em;
    margin-top: 2em;

    @include xs {
        display: block;
    }

    @include m {
        padding-left: 0;
    }
}
