.look {   
    margin-top: 2em;
    margin-bottom: 2em;
     
    ~ .look[data-dir="rtl"] {
        direction: rtl;
        
        > * {
            direction: ltr;
        }
        
        @include m {
            .content {
                border-left: none;
                border-right: 1px solid $color-light-grey;
            }
        }
    }
    
    .content {
        @include m {
            border-left: 1px solid $color-light-grey;
        }
        
        .content-inner {
            padding: 1.5em 0;
            
            .title {
                font-size: 2rem;
            }
                
            .short-desc {
                @extend .desc;
                font-weight: bold;
                color: $color-grey;
                font-weight: 500;
                font-size: 1rem;
            }
            
            .desc {
                font-weight: 400;
            }
    
            .images {
                overflow: hidden;
                margin: 3em -0.5em 1.5em;
                
                .image {
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                    width: 33.33333%;
                    float: left;
                }
                
                &[data-items="1"] {
                   .image {
                       width: 100%;
                   }
                }
                
                &[data-items="2"] {
                   .image:nth-child(1) {
                       width: 66.66666%;
                   }
                }
                
                &[data-items="3"] {
                    .image {
                        width: 33.33333%;
                   }
                }
            }
        }
    }
}