#brands ul {
    @include xxs {
        column-count: 2;
        column-width: auto;
    }
    
    @include s {
        column-count: 4;
    }
    
    li {
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
    }
}

#brand-slider {
    overflow: hidden;
    margin: 2.5rem 0;
    
    .slick-slide {
        height: em(55);
        filter: grayscale(100%);
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin: 0 1.5em;
    }
}

#brands-intro {
    .content {
        position: relative;
        background: $color-light-grey;
        padding: 1em;
        
        @include m {
            padding: 2em 3em;
        }
    }
    
    @each $h in h1, h2, h3, h4, h5, h6 {
        #{$h} { 
            @extend .title;
        }
    }
    
    p {
        @extend .desc;
        margin-top: 0;
    }
}

#brand-list {
    overflow: hidden;
    margin-top: 3em;
    
    @include m {
        margin-left: 3em;
    }
    
    li {
        float: left;
        margin-right: em(12);
        
        .cat {
            font-size: em(11);
            color: $color-primary;
            text-transform: uppercase;
        }
        
        .name {
            font-size: em(32);
            font-weight: 300;
        }
    }
}