.slider:not(.slick-initialized) > *:not(:first-child) {
    display: none;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    
    &.slick-loading {
        .slick-list {
            background: url( '../gfx/ajax-loader.gif' ) center center no-repeat / 6em 6em;
        }
        
        .slick-track {
            visibility: hidden;
        }

        .slick-slide {
            visibility: hidden;
        }
    }
    
    &.slick-initialized .slick-slide {
        display: block !important;
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
        transform: translate3d(0, 0, 0);
    
        &:focus {
            outline: none;
        }
        
        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
        
        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            transform: translate3d(0, 0, 0);
            
            &:before,
            &:after {
                display: table;
                content: '';
            }
            
            &:after {
                clear: both;
            }

            .slick-slide {
                display: none;
                float: left;
                height: 100%;
                min-height: 1px;
                outline: none;
                
                & img {
                    display: block;
                }
                
                &.slick-loading img {
                    display: none;
                }
                
                &.dragging img {
                    pointer-events: none;
                }

            }

            .slick-arrow.slick-hidden {
                display: none;
            }
            
            .slick-prev,
            .slick-next {
                font-size: 0;
                line-height: 0;
                position: absolute;
                top: 50%;
                display: block;
                width: 20px;
                height: 20px;
                padding: 0;
                transform: translate(0, -50%);
                cursor: pointer;
                color: transparent;
                border: none;
                outline: none;
                background: transparent;
                
                &:before {
                    font-size: 20px;
                    line-height: 1;
                    opacity: .75;
                    color: white;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                
                &.slick-disabled:before {
                    opacity: .25;
                }
                
                &:hover,
                &:focus {
                    color: transparent;
                    outline: none;
                    background: transparent;
                    
                    &::before {
                        opacity: 1;
                    }
                }
            }
            
            .slick-prev {
                left: -25px;
            }
            
            .slick-next {
                right: -25px;
            }
            
            .slick-dots {
                position: absolute;
                bottom: -25px;
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
                list-style: none;
                text-align: center;
            
                li {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: 0 5px;
                    padding: 0;
                    cursor: pointer;
                    
                    &.slick-active button::before {
                        opacity: .75 !important;
                    }
                    
                    button {
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        width: 20px;
                        height: 20px;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: none;
                        background: transparent;
            
                        &::before {
                            font-size: 6px;
                            line-height: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            content: '•';
                            text-align: center;
                            opacity: .25;
                            color: black;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                        
                        &:hover,
                        &:focus {
                            outline: none;
            
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}