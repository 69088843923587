#news-archive {
    margin-top: 5rem;

    .flex-box {
        .caps-title {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 1.25rem;
            color: $color-grey;

            &:before {
                border-color: $color-grey;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 2em;
                background: linear-gradient(90deg, force-rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }
        }

        .news-title {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            font-size: em(14);
            font-weight: 500;
            line-height: 1.5em;
            max-height: 3em;
            overflow: hidden;
            transition: color 0.3s;

            &:hover {
                color: $color-primary;
            }
        }
    }

    .next-items {
        float: right;
    }
}

#latest-news {
    .latest-item {
        position: absolute;
        height: 100%;
        left: 0.5em;
        right: 0.5em;
    }

    .flex-box {
        position: absolute;
        height: 100%;
    }

    .flex-col {
        height: 100%;
    }

    .info-box {
        padding: 0 2.5rem;
    }

    .bg-image {
        flex: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

#news-list {
    .flex-box {
        @include m {
            border-left: 1px solid $color-light-grey;
        }

        .flex-col {
            display: block;
            width: 50%;
            flex-basis: 50%;
            padding-left: 0.5em;
            padding-right: 0.5em;

            &:last-child {
                display: none !important;
            }

            &:nth-child(n+7) {
                display: none;
            }

            @include xxs {
                width: 33%;
                flex-basis: 33%;
            }

            @include xs {
                width: 25%;
                flex-basis: 25%;

                &:nth-child(n+7) {
                    display: block;
                }

                &:nth-child(n+9) {
                    display: none;
                }
            }

            @include s {
                width: 20%;
                flex-basis: 20%;

                &:nth-child(n+9) {
                    display: block;
                }
            }

            @include m {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block !important;
                }
            }
        }
    }
}
