#pagination {
    display: flex;
    width: 100%;
    padding: 0;
    padding-top: 2em;
    margin: 0;
    div {
        a {
            float: left;
            transition: background 0.3s ease;
            &:not(.button) {
                padding: 8px 16px;
            }
            &:hover {
                color: #e1001a;
            }
        }
        &.previous {
            float: left;
        }
        &.pages {
            display: inline-block;
            margin: 0 auto;
            div {
                display: inline-block;
            }
            .active {
                color: #e1001a;
            }
        }
        &.next {
            float: right;
        }
    }
}
