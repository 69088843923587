#footer {
    overflow: hidden;
    margin-top: 3em;
    padding: 2em 0;
    background: $color-light-grey;
    
    @include m {
        margin-top: em(88);
    }

    #footer-inner {
        .footer-desc {
            @each $h in h1,h2,h3,h4,h5,h6 {
                #{$h} {
                    @extend .caps-title;
                }
            }
            
            p {
                font-size: em(12);
                font-weight: 300;
                line-height: (18/12);
                color: $color-grey;
                margin: rem(24) 0;
            }
            
            form {
                overflow: hidden;
                margin-bottom: em(48);
    
                input[type="email"] {
                    width: calc(100% - 120px);
                    max-width: rem(260);
                    display: inline-block;
                    float: left;
                    height: rem(36);
                    font-size: em(12);
                    line-height: rem(36);
                    padding: 0 rem(16);
                }
    
                input[type="submit"] {
                    width: rem(120);
                    float: left;
                    height: rem(36);
                    overflow: hidden;
                }
            }
        }

        a {
            transition: color 0.3s;

            &:hover {
                color: $color-primary;
            }
        }

        .list-1 {
            overflow: hidden;
            margin: em(24) 0;

            li {
                font-size: em(12);
                font-weight: 300;
                line-height: (28/16);

                @include s {
                    font-size: em(14);
                }

                a {
                    display: inline-block;
                }
            }
        }

        .list-2 {
            overflow: hidden;
            margin-top: em(24);

            li {
                float: left;
                font-size: em(12);
                font-weight: 500;
                line-height: (18/13);
                margin-right: 1.5rem;
            }
        }

        .social {
            margin-top: rem(24);

            @include xxs {
                float: right;
            }
        }
    }
}
